.App-logo {
    height: 20vmin;
    pointer-events: none;
}

.root {
    flex-grow: 1;
}


.grow {
    flex-grow: 1;
}

.list {
    width: 250;
}

.menuButton {
    margin-left: -12;
    margin-right: 20;
}
