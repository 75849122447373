.image-container {
    position: relative;
}

.image {
    max-width: 100%;
    max-height: auto;
    display: block;
}


.image-overlay {
    position: absolute;
    bottom: 0;
    background: rgb(255, 133, 133);
    background: rgba(255, 133, 133, 0.5);
    /* Black see-through */
    color: #000000;

    width: 70px;
    height: 70px;
    border-radius:35px;
    font-size: 20px;
    text-align: center;
}

.overlay {
    position: absolute;

    text-align: center;
    background: rgb(255, 133, 133);
    background: rgba(255, 133, 133, 0.5);
    color: #000000;

    /* display: flex; */
    align-items: center;
    justify-content: center;
    font-size: 1em;

    width: 0;
    height: 0;
    border-radius: 50%;
    left:0;
    top: 0;
}